<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00002 1.99984C4.68631 1.99984 2.00002 4.68613 2.00002 7.99984C2.00002 11.3135 4.68631 13.9998 8.00002 13.9998C11.3137 13.9998 14 11.3135 14 7.99984C14 4.68613 11.3137 1.99984 8.00002 1.99984ZM0.666687 7.99984C0.666687 3.94975 3.94993 0.666504 8.00002 0.666504C12.0501 0.666504 15.3334 3.94975 15.3334 7.99984C15.3334 12.0499 12.0501 15.3332 8.00002 15.3332C3.94993 15.3332 0.666687 12.0499 0.666687 7.99984ZM11.4714 5.52843C11.7318 5.78878 11.7318 6.21089 11.4714 6.47124L7.47142 10.4712C7.21108 10.7316 6.78896 10.7316 6.52862 10.4712L4.52862 8.47124C4.26827 8.21089 4.26827 7.78878 4.52862 7.52843C4.78896 7.26808 5.21108 7.26808 5.47142 7.52843L7.00002 9.05703L10.5286 5.52843C10.789 5.26808 11.2111 5.26808 11.4714 5.52843Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
