<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/clock">
      <path
        id="Icon (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99992 2.2487C4.10042 2.2487 1.74992 4.5992 1.74992 7.4987C1.74992 10.3982 4.10042 12.7487 6.99992 12.7487C9.89941 12.7487 12.2499 10.3982 12.2499 7.4987C12.2499 4.5992 9.89941 2.2487 6.99992 2.2487ZM0.583252 7.4987C0.583252 3.95487 3.45609 1.08203 6.99992 1.08203C10.5437 1.08203 13.4166 3.95487 13.4166 7.4987C13.4166 11.0425 10.5437 13.9154 6.99992 13.9154C3.45609 13.9154 0.583252 11.0425 0.583252 7.4987ZM6.99992 3.41536C7.32209 3.41536 7.58325 3.67653 7.58325 3.9987V7.13818L9.59413 8.14362C9.88228 8.28769 9.99908 8.63809 9.855 8.92624C9.71093 9.21439 9.36053 9.33119 9.07238 9.18711L6.73904 8.02045C6.54142 7.92164 6.41659 7.71965 6.41659 7.4987V3.9987C6.41659 3.67653 6.67775 3.41536 6.99992 3.41536Z"
        fill="#6D6D6D"
      />
    </g>
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
