<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2.75C5.27208 2.75 2.25 5.77208 2.25 9.5C2.25 13.2279 5.27208 16.25 9 16.25C12.7279 16.25 15.75 13.2279 15.75 9.5C15.75 5.77208 12.7279 2.75 9 2.75ZM0.75 9.5C0.75 4.94365 4.44365 1.25 9 1.25C13.5563 1.25 17.25 4.94365 17.25 9.5C17.25 14.0563 13.5563 17.75 9 17.75C4.44365 17.75 0.75 14.0563 0.75 9.5ZM9 5C9.41421 5 9.75 5.33579 9.75 5.75V13.25C9.75 13.6642 9.41421 14 9 14C8.58579 14 8.25 13.6642 8.25 13.25V5.75C8.25 5.33579 8.58579 5 9 5ZM12 8C12.4142 8 12.75 8.33579 12.75 8.75V13.25C12.75 13.6642 12.4142 14 12 14C11.5858 14 11.25 13.6642 11.25 13.25V8.75C11.25 8.33579 11.5858 8 12 8ZM6 9.5C6.41421 9.5 6.75 9.83579 6.75 10.25V13.25C6.75 13.6642 6.41421 14 6 14C5.58579 14 5.25 13.6642 5.25 13.25V10.25C5.25 9.83579 5.58579 9.5 6 9.5Z"
      fill="#6D6D6D"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
