<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6663 7.98505C14.6581 4.31611 11.6725 1.3335 8.00275 1.3335C4.33366 1.3335 1.3486 4.31508 1.33933 7.98318C1.33909 7.98909 1.33594 7.99404 1.33594 8.00002C1.33594 8.006 1.33909 8.01095 1.33933 8.01686C1.3486 11.6853 4.33362 14.6668 8.00275 14.6668C11.6725 14.6668 14.6581 11.6842 14.6663 8.01499C14.6664 8.00972 14.6693 8.00533 14.6693 8.00002C14.6693 7.99471 14.6664 7.99033 14.6663 7.98505ZM13.7754 7.56523H10.8852C10.9913 5.83838 11.9256 4.60891 12.3277 4.15533C13.1481 5.07791 13.6779 6.26075 13.7754 7.56523ZM8.43754 7.56523V2.22509C9.67645 2.31776 10.8086 2.79717 11.7093 3.54917C11.2521 4.07324 10.1499 5.53924 10.0289 7.56523H8.43754ZM7.56795 7.56523H5.95493C5.83526 5.54115 4.74626 4.07635 4.2935 3.55158C5.19468 2.7983 6.32776 2.31786 7.56795 2.22509V7.56523ZM7.56795 8.43481V13.7752C6.3197 13.6819 5.18056 13.195 4.27651 12.4332C5.37786 10.9883 5.84471 9.63445 5.94414 8.43481H7.56795ZM8.43754 8.43481H10.0398C10.1403 9.6352 10.6124 10.9898 11.7263 12.4357C10.8227 13.1961 9.68456 13.6819 8.43754 13.7752V8.43481ZM3.67524 4.15816C4.07543 4.61574 4.99459 5.84358 5.09918 7.56523H2.23008C2.32749 6.26206 2.85629 5.08032 3.67524 4.15816ZM2.23008 8.43481H5.07848C4.97445 9.44625 4.56783 10.588 3.65253 11.8171C2.84632 10.8986 2.32657 9.72617 2.23008 8.43481ZM12.3501 11.8203C11.4231 10.59 11.0113 9.44706 10.9061 8.43481H13.7754C13.6788 9.72769 13.158 10.9013 12.3501 11.8203Z"
      fill="#191414"
      stroke="#191414"
      stroke-width="0.3"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
