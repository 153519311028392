<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33301 3.99998C1.33301 3.63179 1.63148 3.33331 1.99967 3.33331H13.9997C14.3679 3.33331 14.6663 3.63179 14.6663 3.99998C14.6663 4.36817 14.3679 4.66665 13.9997 4.66665H1.99967C1.63148 4.66665 1.33301 4.36817 1.33301 3.99998ZM1.33301 7.99998C1.33301 7.63179 1.63148 7.33331 1.99967 7.33331H13.9997C14.3679 7.33331 14.6663 7.63179 14.6663 7.99998C14.6663 8.36817 14.3679 8.66665 13.9997 8.66665H1.99967C1.63148 8.66665 1.33301 8.36817 1.33301 7.99998ZM1.33301 12C1.33301 11.6318 1.63148 11.3333 1.99967 11.3333H13.9997C14.3679 11.3333 14.6663 11.6318 14.6663 12C14.6663 12.3682 14.3679 12.6666 13.9997 12.6666H1.99967C1.63148 12.6666 1.33301 12.3682 1.33301 12Z"
      fill="#CDCDCD"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
