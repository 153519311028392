<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99308 1.33351C7.11684 1.332 6.24901 1.50435 5.43984 1.84055C4.63067 2.17676 3.89624 2.67016 3.27908 3.29217C0.680411 5.89084 0.680411 10.1208 3.27908 12.7208C4.54241 13.9842 6.21574 14.6795 7.99308 14.6795C9.77041 14.6795 11.4444 13.9842 12.7071 12.7208C15.3064 10.1215 15.3064 5.89151 12.7071 3.29217C12.0899 2.67016 11.3555 2.17676 10.5463 1.84055C9.73714 1.50435 8.86931 1.332 7.99308 1.33351ZM11.7644 11.7782C11.0655 12.4794 10.1842 12.9711 9.22041 13.1975L9.21974 13.1728C9.21863 12.6577 9.31382 12.147 9.50041 11.6668L8.25841 11.1808C7.9911 11.8684 7.86592 12.6029 7.89041 13.3402C6.51077 13.3165 5.1947 12.7559 4.22175 11.7775C3.24696 10.8028 2.68945 9.48717 2.66708 8.10884C2.72041 8.11017 2.77374 8.11217 2.82708 8.11217C3.50852 8.11403 4.18424 7.98784 4.81908 7.74017L4.33241 6.49884C3.84749 6.68586 3.33145 6.77907 2.81174 6.77351C3.03565 5.8121 3.52405 4.9325 4.22175 4.23417C4.94607 3.50646 5.86588 3.0044 6.86974 2.78884C6.86337 3.2324 6.78293 3.67178 6.63174 4.08884L7.88575 4.54284C8.0968 3.96588 8.20554 3.35652 8.20708 2.74217C8.20708 2.72017 8.20441 2.69884 8.20374 2.67684C9.54574 2.72951 10.8037 3.27417 11.7651 4.23417C12.714 5.18383 13.2676 6.45786 13.3144 7.79951C12.6812 7.79217 12.0518 7.89899 11.4564 8.11484L11.9124 9.36817C12.3259 9.2164 12.762 9.13505 13.2024 9.12751C12.9897 10.1316 12.4902 11.0524 11.7644 11.7782Z"
      fill="#191414"
    />

    <path
      d="M9.40794 9.42008C9.17875 9.64889 8.97202 9.89914 8.7906 10.1674L9.8946 10.9141C10.0279 10.7167 10.1813 10.5314 10.3499 10.3634C10.5866 10.127 10.8502 9.91916 11.1353 9.74408L10.4359 8.60875C10.0627 8.8381 9.71773 9.1104 9.40794 9.42008ZM5.63727 5.64941C5.46812 5.81768 5.28353 5.96968 5.08594 6.10341L5.83194 7.20875C6.10015 7.02697 6.35059 6.82028 6.57994 6.59141C6.8892 6.28125 7.16146 5.93628 7.39127 5.56341L6.25594 4.86475C6.08057 5.14922 5.87298 5.41251 5.63727 5.64941Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
