<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/arrow-left">
      <path
        id="Icon (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.47149 2.86323C8.73184 3.12358 8.73184 3.54569 8.47149 3.80604L4.94289 7.33464H12.6667C13.0349 7.33464 13.3334 7.63311 13.3334 8.0013C13.3334 8.36949 13.0349 8.66797 12.6667 8.66797H4.94289L8.47149 12.1966C8.73184 12.4569 8.73184 12.879 8.47149 13.1394C8.21114 13.3997 7.78903 13.3997 7.52868 13.1394L2.86201 8.47271C2.60166 8.21236 2.60166 7.79025 2.86201 7.5299L7.52868 2.86323C7.78903 2.60288 8.21114 2.60288 8.47149 2.86323Z"
        fill="#191414"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
