<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9997 8.99999C12.9997 8.4477 12.552 7.99999 11.9997 7.99999C11.4474 7.99999 10.9997 8.4477 10.9997 8.99999V13C10.9997 13.5523 11.4474 14 11.9997 14C12.552 14 12.9997 13.5523 12.9997 13V8.99999Z"
      fill="#191414"
    />
    <path
      d="M11.9997 16C11.4474 16 10.9997 16.4477 10.9997 17C10.9997 17.5523 11.4474 18 11.9997 18H12.0097C12.562 18 13.0097 17.5523 13.0097 17C13.0097 16.4477 12.562 16 12.0097 16H11.9997Z"
      fill="#191414"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9997 8.99999C12.9997 8.4477 12.552 7.99999 11.9997 7.99999C11.4474 7.99999 10.9997 8.4477 10.9997 8.99999V13C10.9997 13.5523 11.4474 14 11.9997 14C12.552 14 12.9997 13.5523 12.9997 13V8.99999ZM11.9997 16C11.4474 16 10.9997 16.4477 10.9997 17C10.9997 17.5523 11.4474 18 11.9997 18H12.0097C12.562 18 13.0097 17.5523 13.0097 17C13.0097 16.4477 12.562 16 12.0097 16H11.9997Z"
      fill="#191414"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8123 1.66837C12.295 1.43834 11.7044 1.43834 11.1871 1.66837C10.7876 1.84602 10.5281 2.15894 10.3475 2.41391C10.1699 2.66461 9.9798 2.99303 9.77072 3.35422L9.74962 3.39067L1.52473 17.5973L1.50359 17.6338C1.29367 17.9963 1.10291 18.3258 0.973562 18.6054C0.842112 18.8895 0.699508 19.2714 0.744957 19.7074C0.803767 20.2715 1.09931 20.7841 1.55802 21.1176C1.91252 21.3753 2.31451 21.4433 2.62627 21.4719C2.93303 21.5 3.3137 21.5 3.73261 21.5H20.2669C20.6858 21.5 21.0664 21.5 21.3732 21.4719C21.6849 21.4433 22.0869 21.3753 22.4414 21.1176C22.9001 20.7841 23.1957 20.2715 23.2545 19.7074C23.2999 19.2714 23.1573 18.8895 23.0259 18.6054C22.8965 18.3258 22.7058 17.9963 22.4959 17.6338L14.2287 3.35419C14.0196 2.99301 13.8295 2.6646 13.652 2.41391C13.4714 2.15894 13.2119 1.84602 12.8123 1.66837ZM11.9795 3.56992C11.8616 3.73637 11.7176 3.98311 11.4805 4.39275L3.25559 18.5994C3.0176 19.0104 2.87472 19.2592 2.78873 19.4451C2.7835 19.4564 2.77874 19.467 2.77441 19.4768C2.78508 19.478 2.7966 19.4791 2.80899 19.4803C3.01296 19.499 3.29986 19.5 3.77484 19.5H20.2246C20.6996 19.5 20.9865 19.499 21.1905 19.4803C21.2029 19.4791 21.2144 19.478 21.225 19.4768C21.2207 19.467 21.216 19.4564 21.2107 19.4451C21.1247 19.2592 20.9818 19.0104 20.7439 18.5994L12.519 4.39274C12.2818 3.98311 12.1378 3.73637 12.0199 3.56992C12.0128 3.55984 12.006 3.55053 11.9997 3.54194C11.9934 3.55053 11.9867 3.55984 11.9795 3.56992Z"
      fill="#191414"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9997 8.99999C12.9997 8.4477 12.552 7.99999 11.9997 7.99999C11.4474 7.99999 10.9997 8.4477 10.9997 8.99999V13C10.9997 13.5523 11.4474 14 11.9997 14C12.552 14 12.9997 13.5523 12.9997 13V8.99999ZM11.9997 16C11.4474 16 10.9997 16.4477 10.9997 17C10.9997 17.5523 11.4474 18 11.9997 18H12.0097C12.562 18 13.0097 17.5523 13.0097 17C13.0097 16.4477 12.562 16 12.0097 16H11.9997Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
