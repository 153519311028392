<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="chevron-selector-vertical">
      <path
        id="Icon"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5286 2.19526C7.78894 1.93491 8.21106 1.93491 8.4714 2.19526L11.8047 5.5286C12.0651 5.78894 12.0651 6.21106 11.8047 6.4714C11.5444 6.73175 11.1223 6.73175 10.8619 6.4714L8 3.60948L5.13807 6.4714C4.87772 6.73175 4.45561 6.73175 4.19526 6.4714C3.93491 6.21106 3.93491 5.78894 4.19526 5.5286L7.5286 2.19526ZM4.19526 9.5286C4.45561 9.26825 4.87772 9.26825 5.13807 9.5286L8 12.3905L10.8619 9.5286C11.1223 9.26825 11.5444 9.26825 11.8047 9.5286C12.0651 9.78895 12.0651 10.2111 11.8047 10.4714L8.4714 13.8047C8.21106 14.0651 7.78894 14.0651 7.5286 13.8047L4.19526 10.4714C3.93491 10.2111 3.93491 9.78895 4.19526 9.5286Z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
