<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H19.5C19.7761 0 20 0.223858 20 0.5V1.5C20 1.77614 19.7761 2 19.5 2H0.5C0.223857 2 0 1.77614 0 1.5V0.5Z"
      fill="#191414"
    />
    <path
      d="M0 6.5C0 6.22386 0.223858 6 0.5 6H19.5C19.7761 6 20 6.22386 20 6.5V7.5C20 7.77614 19.7761 8 19.5 8H0.5C0.223857 8 0 7.77614 0 7.5V6.5Z"
      fill="#191414"
    />
    <path
      d="M0 12.5C0 12.2239 0.223858 12 0.5 12H19.5C19.7761 12 20 12.2239 20 12.5V13.5C20 13.7761 19.7761 14 19.5 14H0.5C0.223857 14 0 13.7761 0 13.5V12.5Z"
      fill="#191414"
    />
    <path
      d="M0 18.5C0 18.2239 0.223858 18 0.5 18H19.5C19.7761 18 20 18.2239 20 18.5V19.5C20 19.7761 19.7761 20 19.5 20H0.5C0.223857 20 0 19.7761 0 19.5V18.5Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
