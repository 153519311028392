<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0001 3C8.67006 3 5.76115 4.80801 4.2035 7.50073C3.92695 7.97879 3.31523 8.14215 2.83716 7.86561C2.3591 7.58906 2.19574 6.97734 2.47228 6.49927C4.37273 3.21398 7.92726 1 12.0001 1C18.0752 1 23.0001 5.92487 23.0001 12C23.0001 18.0751 18.0752 23 12.0001 23C7.92726 23 4.37273 20.786 2.47228 17.5007C2.19574 17.0227 2.3591 16.4109 2.83716 16.1344C3.31523 15.8579 3.92695 16.0212 4.2035 16.4993C5.76115 19.192 8.67006 21 12.0001 21C16.9706 21 21.0001 16.9706 21.0001 12C21.0001 7.02944 16.9706 3 12.0001 3ZM11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071C10.9024 16.3166 10.9024 15.6834 11.2929 15.2929L13.5858 13H2C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11H13.5858L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
