<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H19.5C19.7761 0 20 0.223858 20 0.5V3.5C20 3.77614 19.7761 4 19.5 4H0.5C0.223857 4 0 3.77614 0 3.5V0.5Z"
      fill="#191414"
    />
    <path
      d="M0 8.5C0 8.22386 0.223858 8 0.5 8H19.5C19.7761 8 20 8.22386 20 8.5V11.5C20 11.7761 19.7761 12 19.5 12H0.5C0.223857 12 0 11.7761 0 11.5V8.5Z"
      fill="#191414"
    />
    <path
      d="M0 16.5C0 16.2239 0.223858 16 0.5 16H19.5C19.7761 16 20 16.2239 20 16.5V19.5C20 19.7761 19.7761 20 19.5 20H0.5C0.223857 20 0 19.7761 0 19.5V16.5Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
