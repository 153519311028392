<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1858 2.81498H11.7044V1.3335H4.29695V2.81498H2.81547C2.00065 2.81498 1.33398 3.48164 1.33398 4.29646V5.0372C1.33398 6.92609 2.75621 8.46683 4.58584 8.69646C4.8201 9.25154 5.18598 9.74124 5.65188 10.1233C6.11778 10.5053 6.66969 10.7681 7.25991 10.8891V13.1853H4.29695V14.6668H11.7044V13.1853H8.74139V10.8891C9.33162 10.7681 9.88352 10.5053 10.3494 10.1233C10.8153 9.74124 11.1812 9.25154 11.4155 8.69646C13.2451 8.46683 14.6673 6.92609 14.6673 5.0372V4.29646C14.6673 3.48164 14.0007 2.81498 13.1858 2.81498ZM2.81547 5.0372V4.29646H4.29695V7.12609C3.43769 6.81498 2.81547 6.00016 2.81547 5.0372ZM8.00065 9.48164C6.77843 9.48164 5.77843 8.48164 5.77843 7.25942V2.81498H10.2229V7.25942C10.2229 8.48164 9.22287 9.48164 8.00065 9.48164ZM13.1858 5.0372C13.1858 6.00016 12.5636 6.81498 11.7044 7.12609V4.29646H13.1858V5.0372Z"
      fill="#191414"
    />
  </svg>
</template>
